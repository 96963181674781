<template>
  <b-card no-body class="advance-search mb-4">
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button
        class="text-left"
        block
        href="#"
        v-b-toggle.advanced-search
        variant="success"
      >
        <b-icon-search></b-icon-search>
        {{ $t('page.advanced_search') }}
      </b-button>
    </b-card-header>
    <b-collapse
      id="advanced-search"
      v-model="visible"
      accordion="advanced-search"
    >
      <b-card-body>
        <slot name="advanced-search"></slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
export default {
  name: 'tAdvancedSearch',
  data() {
    return {
      visible: false
    };
  }
};
</script>
<style scoped>
.card-header a {
  align-items: center;
  display: flex;
  font-size: 18px;
  border: solid 1px #acb5c3;
  padding: 6px 10px;
  height: 42px;
  justify-content: unset;
}
.card-header a svg {
  margin-right: 5px;
  font-size: 14px !important;
}
</style>
