<template>
  <div>
    <b-button
      variant="primary"
      class="mr-2"
      @click="expor()"
      size="sm"
      :disabled="exportDisabled || disabled"
      >{{ exportText }}
      <b-icon
        v-if="disabled"
        class="counterclockwise"
        icon="arrow-counterclockwise"
        animation="spin-reverse"
        font-scale="1.2"
      ></b-icon>
      <b-icon
        v-else
        class="counterclockwise"
        icon="file-earmark-spreadsheet"
        font-scale="1.2"
      >
      </b-icon>
    </b-button>
  </div>
</template>

<script>
import JwtService from '@/common/services/jwt';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'ExportReservation',
  data() {
    return {
      disabled: false
    };
  },
  props: {
    exportText: String,
    exportUrl: String,
    exportDisabled: Boolean,
    fileName: String
  },
  components: {},
  methods: {
    expor: function() {
      this.disabled = true;
      let url = this.exportUrl;
      if (this.exportUrl.indexOf("?") > -1) {
        url += '&token=' + JwtService.getToken();
      } else {
        url += '?token=' + JwtService.getToken();
      }
      // eslint-disable-next-line no-useless-escape
      url = url.replace(/\n|\r/g, '').replace(/\ +/g, '');
      console.log(url);
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      var vthis = this;
      xhr.onload = function(x) {
        // console.log(vthis);
        console.log(x);
        if (this.status === 200) {
          var blob = this.response;
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          var name = this.getResponseHeader('content-disposition')
            .split('filename=')[1]
            .replace(new RegExp('"', 'gm'), '');
          reader.onload = function(e) {
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = e.target.result;
            console.log(e);
            // xls
            if (vthis.fileName) {
              link.setAttribute('download', `${vthis.fileName}.xlsx`);
            } else {
              link.setAttribute('download', name);
            }

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          };
          console.log('下载完了');
          vthis.disabled = false;
          vthis.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Download Successful.' // 提示内容
          });
        } else {
          vthis.disabled = false;
          vthis.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Please choose the same supplier name.' // 提示内容
          });
        }
      };
      xhr.send();
    }
  },

  computed: {},
  mounted() {
    // console.log(JwtService.getToken());
  }
};
</script>
<style scoped></style>
