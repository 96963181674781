<template>
  <div>
    <b-button variant="primary" @click="clickModal" :disabled="disabled">
      Stock Out
      <!-- <b-icon icon="folder-check" aria-hidden="true"></b-icon> -->
    </b-button>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Reduce inventory"
      id="invoice_detail"
      ref="modal"
    >
      <b-overlay :show="loading" style="min-height: 150px">
        <b-row style="padding-bottom: 80px">
          <!-- <b-col md="12" class="my-1">
            <b-form-group
              label-for="warehouse_id"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Warehouse"
              class="required"
            >
              <SelectLoading
                v-if="!optionsWarehouse || optionsWarehouse.length == 0"
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="formData.warehouse_id"
                :placeholder="$t('page.please_choose')"
                :options="optionsWarehouse"
                :reduce="options => options.value"
                label="label"
              >
              </sv-select>
            </b-form-group>
          </b-col> -->
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="identification_code"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Identification Code"
              class="required"
            >
              <sv-select
                @search="onSearch"
                class="select-mh-9"
                v-model="formData.identification_code"
                placeholder="Please Type to Select"
                :options="options.identification_code"
                :reduce="options => options.value"
              >
                <!-- <template slot="selected-option" slot-scope="option">
                  {{ option.label.split('-')[0] }}
                </template> -->
              </sv-select>
            </b-form-group>
          </b-col>
          <!-- <b-col md="12" class="my-1">
            <b-form-group
              label-for="brand"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Quantity"
              class="required"
            >
              <b-input-group>
                <b-form-input
                  v-model="formData.qty"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group> </b-form-group
          ></b-col> -->
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template></b-modal
    >
  </div>
</template>
<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
// import SelectLoading from '@/components/Loading/Index';
import _ from 'lodash';
export default {
  name: 'WarehouseReduceInventory',
  data: function() {
    return {
      showModal: false,
      loading: false,
      disabled: false,
      submitDisabled: false,
      formData: {
        warehouse_product_id: 0,
        identification_code: ''
      },
      options: {
        identification_code: []
      }
    };
  },
  components: {},
  props: ['initFn', 'optionsWarehouse'],
  methods: {
    clickModal() {
      this.showModal = true;
      this.formData = {
        warehouse_product_id: 0,
        identification_code: null
      };
    },
    submit() {
      let verify = true;
      if (
        this.formData.identification_code == null ||
        this.formData.identification_code == ''
      )
        verify = false;

      if (!verify) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      }
      this.submitDisabled = true;
      this.loading = true;
      apiBus.warehouse
        .warehouseStockNotInspection(this.formData)
        .then(data => {
          console.log(data);
          this.submitDisabled = false;
          this.loading = false;
          // this.showModal = false;
          this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
          // this.showModal = false;
        })
        .catch(error => {
          this.submitDisabled = false;
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },

    onSearch: function(search, loading) {
      if (search != null && search != '') {
        loading(true);
        this.search(loading, search, this);
      }
    },
    // 做放抖动处理
    search: _.debounce((loading, search, vm) => {
      apiBus.warehouse
        .warehouseInventoryCodeInspection({ code: search, status: 10 })
        .then(data => {
          vm.options.identification_code = data.data.data;
          loading(false);
        })
        .catch(error => {
          console.log(error);
          vm.options.identification_code = [];
          loading(false);
        });
    }, 350),

    init() {}
  },
  computed: {
    // ...mapState({
    //   baseConfig: state => state.baseconfig.baseconfig
    // })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
