<template>
  <div>
    <TAdvancedSearch ref="TAdvancedSearch">
      <template v-slot:advanced-search>
        <Advanced
          v-if="tableSubmit != null"
          :searchConfiguration.sync="searchConfiguration"
          :searchObj.sync="tableSubmit"
          :tableId.sync="tableData.tabId"
        ></Advanced>
      </template>
    </TAdvancedSearch>

    <KTPortlet>
      <template v-slot:body>
        <b-overlay :show="loading" class="head-loading">
          <div class="operate-box">
            <AddInventory
              class="mr-2"
              :initFn="initDataFn"
              :optionsWarehouse="options.delivery_address"
            ></AddInventory>
            <ReduceInventory
              class="mr-2"
              :initFn="initDataFn"
              :optionsWarehouse="options.delivery_address"
            ></ReduceInventory>
            <Export
              v-b-popover.hover.topleft=""
              :exportText="'Export Excel'"
              :exportUrl="exportUrl"
              :exportDisabled="exportDisabled"
            ></Export>
          </div>
          <CustomTable
            v-if="tableSubmit != null"
            :itemsFn="itemsFn"
            :tableData="tableData"
            :sortName="sort_name"
            :tableObj="tableSubmit"
            :tableId.sync="tableData.tabId"
            :options="options"
          ></CustomTable>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import TAdvancedSearch from '@/components/TAdvancedSearch/Index.vue';
import KTPortlet from '@/views/content/Portlet';
import Advanced from '@/components/Search/Ordinary.vue';
import CustomTable from '@/components/Table/Index.vue';
// import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import apiBus from '@/common/apiBus/index';
import { mapState, mapGetters } from 'vuex';
import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import AddInventory from '@/views/pages/manageWarehouse/stock/AddInventory.vue';
import ReduceInventory from '@/views/pages/manageWarehouse/stock/ReduceInventory.vue';
import Export from '@/components/Export/Index.vue';

export default {
  name: 'ManageWarehouse',
  components: {
    TAdvancedSearch,
    KTPortlet,
    Advanced,
    CustomTable,
    AddInventory,
    ReduceInventory,
    Export
  },
  data() {
    return {
      // 高级搜索配置
      loading: false,
      exportUrl: '',
      exportDisabled: false,
      exportErrorText: '',
      searchConfiguration: {
        total_inventory: {
          type: 'checked',
          text: 'Filter',
          class: '',
          allSelected: true,
          md: 12,
          resetVal: [1],
          options: [
            {
              label: 'Only list Total Inventory with available',
              value: 1
            }
          ]
        },
        brand: {
          type: 'input',
          text: 'Brand',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        brand_article_number: {
          type: 'input',
          text: 'Brand Article Number',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        // order_date: {
        //   type: 'datepicker_from_to',
        //   md: '6',
        //   text: 'Order Date',
        //   limit: {
        //     end: 0
        //   },
        //   from: {
        //     placeholder: 'From'
        //   },
        //   to: {
        //     placeholder: 'To'
        //   }
        // },
        // sales_channel: {
        //   type: 'select',
        //   text: 'Sales Channel',
        //   // icon: 'bar-chart-fill',
        //   placeholder: this.$t('page.please_choose'),
        //   multiple: true,
        //   options: []
        // },
        keyword: {
          type: 'input',
          text: 'Keyword',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        warehouse: {
          type: 'select',
          text: 'Warehouse',
          placeholder: this.$t('page.please_choose'),
          // selectable: 'disabled',
          options: []
        }
      },
      tableData: {
        fields: [
          {
            key: 'image',
            label: 'Article Image',
            variant: 'w-110'
          },
          {
            key: 'article_information',
            label: 'Article Information',
            variant: 'mx-300'
          },
          {
            key: 'total_inventory',
            label: 'Total Inventory'
          },
          {
            key: 'available_inventory',
            label: 'Saleable Inventory'
          },
          {
            key: 'saleable_qty',
            label: 'Incoming'
          }
          // {
          //   key: 'total_inventory',
          //   label: 'Total Inventory'
          // },
          // {
          //   key: 'available_inventory',
          //   label: 'Available Inventory'
          // },
          // {
          //   key: 'pre_lock_qty',
          //   label: 'PreLock  Inventory'
          // },
          // {
          //   key: 'saleable_qty',
          //   label: 'Saleable Inventory'
          // }
        ],
        tabId: 'manage_warehouse',
        rowClass: '',
        class: 'new_table',
        items: [],
        status: null,
        totalItems: 9999999999999,
        pageOptions: [10, 30, 50, 100, 200]
      },
      // 搜索提交数据
      tableSubmit: null,
      initialTableSubmit: {
        brand: null,
        brand_article_number: null,
        total_inventory: [1],
        // order_date_from: null,
        // order_date_to: null,
        keyword: null,
        limit: 100,
        page: 1
      },
      sort_name: '',

      // 表格数据
      save_data: [],
      options: {
        // sales_channel: [],
        supplier_list: [],
        delivery_address: []
      }
    };
  },

  methods: {
    searchTableData(data) {
      var tableData = [];
      data.forEach(key => {
        let {
          id,
          image_url,
          brand,
          brand_article_number,
          product_name_en,
          product_name,
          color,
          color_name,
          size,
          qty,
          free_qty,
          product_id,
          // pre_lock_qty,
          saleable_qty
        } = key;

        tableData.push({
          id,
          image: image_url,
          article_information: {
            brand: brand,
            brand_article_number: brand_article_number,
            product_name: product_name_en,
            product_name_cn: product_name,
            color: color,
            color_name: color_name,
            size: size
          },
          total_inventory: qty,
          available_inventory: free_qty,
          saleable_qty,
          product_id,
          action: 'ManageWarehouse'
        });
      });
      return tableData;
    },
    /**
     * param 将要转为URL参数字符串的对象
     * key URL参数字符串的前缀
     * encode true/false 是否进行URL编码,默认为true
     *
     * return URL参数字符串
     */
    urlEncode(param, key, encode) {
      if (param == null) return '';
      var paramStr = '';
      var t = typeof param;
      if (t == 'string' || t == 'number' || t == 'boolean') {
        if (key != 'limit' && key != 'page' && key != 'tab_type') {
          paramStr +=
            '&' +
            key +
            '=' +
            (encode == null || encode ? encodeURIComponent(param) : param);
        }
      } else {
        for (var i in param) {
          var k =
            key == null
              ? i
              : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
          paramStr += this.urlEncode(param[i], k, encode);
        }
      }
      return paramStr;
    },
    exportUrlFn(data) {
      console.log(data);
      this.exportUrl = `/rest/api/warehouse/export-warehouse-inventory?${this.urlEncode(
        data
      )}`;
    },

    itemsFn() {
      this.CACHE.addCache(
        this.currentUser.uid,
        'ManageWarehouse',
        this.tableSubmit
      );
      this.loading = true;
      this.save_data = [];
      let dataSubmit = JSON.parse(JSON.stringify(this.tableSubmit));
      if (dataSubmit.total_inventory.length == 0) {
        dataSubmit.total_inventory.push(0);
      }
      return apiBus.warehouse
        .getInventoryList(dataSubmit)
        .then(data => {
          this.loading = false;
          this.tableData.items = this.searchTableData(data.data.data.list);
          this.tableData.totalItems = data.data.data.total;
          this.tableData.status = data.data.message;
          this.exportUrlFn(this.tableSubmit);
          return this.tableData.items;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.tableData.totalItems = 0;
          return [];
        });
    },

    initDataFn() {
      this.$root.$emit('bv::refresh::table', this.tableData.tabId);
    },

    init() {
      if (this.CACHE.ifCache('ManageWarehouse')) {
        this.tableSubmit = this.CACHE.getCache(
          this.currentUser.uid,
          'ManageWarehouse'
        );
        Object.keys(this.searchConfiguration).forEach(key => {
          if (this.searchConfiguration[key].type == 'checked-all') {
            if (
              this.tableSubmit[key].length ==
              this.searchConfiguration[key].options.length
            ) {
              this.searchConfiguration[key].allSelected = true;
            } else {
              this.searchConfiguration[key].allSelected = false;
            }
          }
        });
        this.$refs.TAdvancedSearch.visible = this.CACHE.getVisible(
          'ManageWarehouse'
        );
      } else {
        this.tableSubmit = this.initialTableSubmit;
      }

      apiBus.purchase
        .getSupplierList()
        .then(data => {
          this.options.supplier_list =
            data.data.data.supplier_list['Other Supplier'];
        })
        .catch(error => {
          console.log(error);
          this.options.supplier_list = [];
        });
    }
  },

  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    }),
    ...mapGetters(['currentUser']) // 获取用户名
  },

  mounted() {
    this.init();
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == 'advanced-search') {
        this.CACHE.addVisible('ManageWarehouse', isJustShown);
      }
    });

    // 高级搜索字段
    if (this.options.delivery_address.length == 0) {
      if (Object.keys(this.baseConfig).length == 0) {
        apiBus.purchase
          .getBaseConfig()
          .then(data => {
            console.log('getBaseConfig数据缓存vuex5');
            this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
            this.options.delivery_address = this.baseConfig.purchase_delivery_to;
            this.searchConfiguration.warehouse.options = this.options.delivery_address;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.options.delivery_address = this.baseConfig.purchase_delivery_to;
        this.searchConfiguration.warehouse.options = this.options.delivery_address;
      }
    } else {
      this.searchConfiguration.warehouse.options = this.options.delivery_address;
    }
  },
  watch: {}
};
</script>
<style scoped></style>
