<template>
  <div>
    <b-button variant="primary" @click="clickModal" :disabled="disabled">
      Stock In
      <!-- <b-icon icon="folder-check" aria-hidden="true"></b-icon> -->
    </b-button>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      size="lg"
      title="Add inventory"
      id="invoice_detail"
      ref="modal"
    >
      <b-overlay :show="loading">
        <b-row style="padding-bottom: 80px">
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="warehouse_id"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Warehouse"
              class="required"
            >
              <SelectLoading
                v-if="!optionsWarehouse || optionsWarehouse.length == 0"
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="formData.warehouse_id"
                :placeholder="$t('page.please_choose')"
                :options="optionsWarehouse"
                :reduce="options => options.value"
                label="label"
              >
                <!-- :clearable="false" -->
              </sv-select>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1" v-if="0">
            <b-form-group
              label-for="inventory_mode"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Inventory Mode"
              class="required"
            >
              <SelectLoading
                v-if="
                  !options.inventory_mode || options.inventory_mode.length == 0
                "
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="formData.inventory_mode"
                :placeholder="$t('page.please_choose')"
                :options="options.inventory_mode"
                :reduce="options => options.value"
                :clearable="false"
                @input="onInput($event)"
                label="label"
              >
              </sv-select>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="reason"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Reason"
              class="required"
            >
              <SelectLoading
                v-if="!options.reason || options.reason.length == 0"
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="formData.reason"
                :placeholder="$t('page.please_choose')"
                :options="options.reason"
                :reduce="options => options.value"
                label="label"
              >
              </sv-select>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1" v-if="0">
            <b-form-group
              label-for="storage_qty"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Quantity"
              class="required"
            >
              <b-input-group>
                <b-form-input
                  v-model.number="formData.storage_qty"
                  type="number"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="identification_code"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Identification Code"
              class="required"
            >
              <b-input-group>
                <b-form-textarea
                  id="identification_code"
                  v-model="formData.identification_code"
                  rows="8"
                  :placeholder="$t('page.please_choose_textarea')"
                ></b-form-textarea>
              </b-input-group>
              <!-- <template slot="selected-option" slot-scope="option">
                  {{ option.label.split('-')[0] }}
                </template> -->
              <!-- <sv-select
                @search="onSearch"
                class="select-mh-9"
                v-model="formData.identification_code"
                placeholder="Please Type to Select"
                :options="options.identification_code"
                :reduce="options => options.value"
              >
                
              </sv-select> -->
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="brand"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Brand"
              class="required"
            >
              <b-input-group>
                <b-form-input
                  v-model="formData.brand"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group> </b-form-group
          ></b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="brand_article_number"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Brand Official Article No."
              class="required"
            >
              <b-input-group>
                <b-form-input
                  v-model="formData.brand_article_number"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group> </b-form-group
          ></b-col>

          <b-col md="12" class="my-1">
            <b-form-group
              label-for="color"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Color"
            >
              <b-input-group>
                <!-- <template v-slot:append>
                  <b-input-group-text>11</b-input-group-text> -->
                <!-- </template> -->
                <b-form-input
                  v-model="formData.color"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group> </b-form-group
          ></b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="size"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Size"
            >
              <b-input-group>
                <b-form-input
                  v-model="formData.size"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group> </b-form-group
          ></b-col>

          <b-col md="12" class="my-1">
            <b-form-group
              label-for="product_name_cn"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Product Name CN"
            >
              <b-input-group>
                <b-form-input
                  v-model="formData.product_name_cn"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group> </b-form-group
          ></b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="product_name_en"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Product Name EN"
            >
              <b-input-group>
                <!-- <template v-slot:append>
                  <b-input-group-text>11</b-input-group-text> -->
                <!-- </template> -->
                <b-form-input
                  v-model="formData.product_name_en"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group> </b-form-group
          ></b-col>

          <b-col md="12" class="my-1">
            <b-form-group
              label-for="ean_number"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Ean Number"
            >
              <b-input-group>
                <b-form-input
                  v-model="formData.ean_number"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group> </b-form-group
          ></b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="origin_country"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Origin Country"
            >
              <b-input-group>
                <SelectLoading
                  v-if="
                    !options.origin_country ||
                      options.origin_country.length == 0
                  "
                  :size="0.7"
                ></SelectLoading>
                <sv-select
                  v-model="formData.origin_country"
                  :placeholder="$t('page.please_choose')"
                  :options="options.origin_country"
                  :reduce="options => options.value"
                  label="label"
                >
                  <!-- :clearable="false" -->
                </sv-select>
              </b-input-group>
            </b-form-group></b-col
          >
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template></b-modal
    >
  </div>
</template>
<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import SelectLoading from '@/components/Loading/Index';
import _ from 'lodash';
export default {
  name: 'WarehouseEntranceAddinventory',
  data: function() {
    return {
      showModal: false,
      loading: false,
      disabled: false,
      submitDisabled: false,
      formData: {
        warehouse_id: '',
        warehouse_product_id: 0,
        // inventory_mode: 0,
        identification_code: '',
        // storage_qty: '',
        brand: '',
        brand_article_number: '',
        product_name_cn: '',
        product_name_en: '',
        color: '',
        size: '',
        ean_number: '',
        origin_country: '',
        reason: ''
      },
      options: {
        inventory_mode: [
          { label: 'Inventory by Identification Code', value: 0 },
          { label: 'Inventory by Product information', value: 1 }
        ],
        reason: [
          { label: '正常销售入库', value: 10 },
          { label: '得物HK退件', value: 15 },
          { label: '得物个人退件', value: 20 },
          { label: 'APP退件', value: 25 },
          { label: 'PDD退件', value: 30 },
          { label: '备货采买', value: 35 },
          { label: '商户错发', value: 40 },
          { label: '国内补采', value: 45 },
          { label: '魔豆专属', value: 50 },
          { label: '其他原因', value: 5 }
        ],
        origin_country: [],
        identification_code: []
      }
    };
  },
  components: { SelectLoading },
  props: ['initFn', 'optionsWarehouse'],
  methods: {
    clickModal() {
      this.showModal = true;
      this.formData = {
        warehouse_id: '',
        warehouse_product_id: 0,
        // inventory_mode: 0,
        identification_code: '',
        // storage_qty: '',
        brand: '',
        brand_article_number: '',
        product_name_cn: '',
        product_name_en: '',
        color: '',
        size: '',
        ean_number: '',
        origin_country: '',
        reason: ''
      };
    },
    onInput(e) {
      // this.formData.inventory_mode = '';
      switch (e) {
        case 0:
          this.formData.brand = '';
          this.formData.brand_article_number = '';
          this.formData.color = '';
          this.formData.size = '';
          this.formData.product_name_cn = '';
          this.formData.product_name_en = '';
          this.formData.ean_number = '';
          this.formData.storage_qty = '';
          this.formData.origin_country = '';
          break;
        case 1:
          this.formData.identification_code = '';
          break;
        default:
          this.formData.identification_code = '';
          break;
      }
    },

    onSearch: function(search, loading) {
      if (search != null && search != '') {
        loading(true);
        this.search(loading, search, this);
      }
    },
    // 做放抖动处理
    search: _.debounce((loading, search, vm) => {
      apiBus.warehouse
        .warehouseInventoryCodeInspection({ code: search, status: 20 })
        .then(data => {
          vm.options.identification_code = data.data.data;
          loading(false);
        })
        .catch(error => {
          console.log(error);
          vm.options.identification_code = [];
          loading(false);
        });
    }, 350),

    // removePropertyOfNull(obj) {
    //   Object.keys(obj).forEach((item) => {
    //     if (!obj[item]) delete obj[item];
    //   });
    //   return obj;
    // },

    submit() {
      let verify = true;
      console.log(this.from);
      if (
        this.formData.warehouse_id == null ||
        this.formData.warehouse_id == '' ||
        this.formData.reason == null ||
        this.formData.reason == ''
      ) {
        verify = false;
      }

      if (
        this.formData.brand == null ||
        this.formData.brand == '' ||
        this.formData.brand_article_number == null ||
        this.formData.brand_article_number == '' ||
        this.formData.identification_code == null ||
        this.formData.identification_code == ''
      ) {
        verify = false;
      }

      if (!verify) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      }
      this.submitDisabled = true;
      this.loading = true;
      apiBus.warehouse
        .addWarehouseStockInNewProductInspection({
          product_info: this.formData
        })
        .then(data => {
          console.log(data);
          this.submitDisabled = false;
          this.loading = false;
          // this.showModal = false;
          this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch(error => {
          this.submitDisabled = false;
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },

    init() {
      apiBus.purchase
        .getCountryList()
        .then(data => {
          this.options.origin_country = data.data.data.country_list;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    // ...mapState({
    //   baseConfig: state => state.baseconfig.baseconfig
    // })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
